  html {
    scroll-behavior: smooth;
  }
  .container {
    max-width: 960px;
  }
  .flex-equal > * {
    -ms-flex: 1;
    flex: 1;
  }
  .overflow-hidden { overflow: hidden; }
  .body__donate-absolute-container-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .body__title {
    margin-right: 10px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
  }
  .body__money-box-conatiner {
    /* padding: 40px 110px 0px 100px; */
    margin-top: 50px;
  }
  .body__money-box-conatiner > div {
    border-bottom: 1px solid gray;
  }
  .body__progressbar-container {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .dashboard__progressbar {
    width: 100%;
    margin-right: 10px;
  }
  .dashboard__progressbar-bus-text {
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 0px;
    color: white;
  }
  .dashboard__progressbar-bus-text-circle {
    background-color: green;
    padding: 6px 12px 6px 12px;
    border-radius: 20px;
    -webkit-animation: ease infinite;
    -webkit-animation-name: zoom2;
    -webkit-animation-duration: 3s;
    position: relative;
  }
  .dashboard__pregress-subtitle {
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    text-align: center;
  }
  .dashboard__main-text {
    color: lightblue;
    font-weight: 400 !important;
    background-color: black;
    opacity: 0.7;
    border-radius: 9px;
    padding: 10px;
  }
  .dashboard__supernova-text {
    color: ghostwhite;
    font-weight: 600;
    margin: 0px;
  }
  .linkedinImage {
    width: 50px;
  }
  .illustration-1 {
    right: 0;
    margin-top: -230px;
    width: 300px;
    z-index: 1;
    position: absolute;
  }
  .illustration-2 {
    margin-top: -219px;
    width: 450px;
    left: 0;
    z-index: 1;
    position: absolute;
  }
  .illustration-3 {
    height: 322px;
    transform: translateY(0%);
    animation: floater 10s infinite;
    transition: ease 0.5s;
  }
  .phone-wrapper {
    margin-top: 55px;
    /* height: 60vh; */
    /* background-color: lightslategray; */
    /* background-color: midnightblue; */
    background: linear-gradient(midnightblue, black);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  @keyframes zoom {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.5);
    }
  }
  @keyframes zoom2 {
    0%{
      transform: scale(1);
    }
    100%{
        transform: scale(1.1);
    }
  }
  .phone {
    width: 230px;
    background-color: black;
    height: 380px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    /*transform: rotate(360deg);*/
    -webkit-animation:spin 60s linear infinite;
    -moz-animation:spin 60s linear infinite;
    animation: /*zoom 10s alternate infinite, */ spin 60s linear infinite;
  }
  .phone-screen {
    background-color: cornflowerblue;
    width: 90%;
    height: 90%;
    border-radius: 6px;
  }
  .phone-top-noch {
    background-color: black;
    height: 20px;
    border-radius: 0px 0px 50px 50px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .icon-left-space {
    margin-left: 10px;
  }
  .icon-right-space {
    margin-right: 10px;
    background-color: lavenderblush;
    border-radius: 20px;
    padding: 3px;
    border: green 2px solid;
  }
  .check-sqre-icon {
    background-color: white;
    border-radius: 40px;
    padding: 10px;
    width: 80px;
    height: 80px;
  }
  .contact-container {
    background-color: #353839;
    padding: 20px;
    width: fit-content;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 2px;
    margin: 20px auto 20px auto;
    box-shadow: 6px 10px 39px 10px rgba(0,0,0,0.65);
    -webkit-box-shadow: 6px 10px 39px 10px rgba(0,0,0,0.65);
    -moz-box-shadow: 6px 10px 39px 10px rgba(0,0,0,0.65);
  }
  .tech-icon-container {
    justify-items: center;
  }
  .wsp-icon {
    z-index: 1;
  }
  .luciano-mobile {
    border-top: 16px solid #B5B5B5;
  }
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
      100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
  } 
  @keyframes floater {
    0%{transform: translateY(0%);transition: ease 0.5s;}
    50%{transform: translateY(-10%);transition: ease 0.5s;}
  }
  .linkedin-wrapper {
    padding-bottom: 20px;
  }
  .main{
      position: relative;
      top: 50%;
      left: 50%;
      height: 1px;
      width: 1px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: -154px -100px 2px 2px #fff, 18px 116px 3px 0px #fff, -20px -48px 6px 3px #fff, -199px 38px 3px 1px #fff, -41px -131px 0px 3px #fff, 138px 123px 5px 3px #fff, 42px 41px 3px 2px #fff, -180px 9px 0px 1px #fff, 280px -30px 1px 4px #fff, 310px 58px 3px 1px #ffe, 196px -107px 3px 3px #fff, 172px -5px 16px 0px #fff;
      animation: zoom 10s alternate infinite;
  }
  .image-w100 {
    width: 100%;
  }
  .image-w100-desktop {
    width: 100%;
    height: 100vh;
  }
  .tech-icon {
    width: 120px;
    height: 120px;
  }
  .contact-mobile, .about-mobile {
    display: none;
  } 
  .no-underline {
    text-decoration: none !important;
  }
  .dashboard__payment-container {
    align-items: center;
    background: linear-gradient(0deg, #999999, #040B1C);
  }
  
  @media only screen and (max-width: 900px) {
    .contact-container {
      font-size: 25px;
    }
    .tech-icon {
      width: 80px;
      height: 80px;
    }
    .dashboard__payment-container {
      /* padding: 40px; */
      height: auto;
    }
    .phone-wrapper {
      min-height: 500px;
      height: auto;
    }
    .image-w100-desktop {
      height: auto;
    }
  }
  @media (min-width: 768px) {
    .flex-md-equal > * {
      -ms-flex: 1;
      flex: 1;
    }
  }
  @media (max-width: 768px) {
    .illustration-1, .illustration-2 {
      display: none;
    }
    .body__money-box-conatiner {
      margin-top: 0px;
    }
    .contact-mobile, .about-mobile {
      display: block;
    }    
    .contact-desktop, .about-desktop {
      display: none;
    }
  }
  @media only screen and (max-width: 500px) {
    .contact-container {
      text-align: left;
      font-size: 18px;
    }
    .tech-icon {
      width: 70px;
      height: 70px;
    }
    .body__title {
      font-size: 35px;
    }
    .dashboard__pregress-subtitle {
      font-size: 23px;
    }
  }
  /* @media only screen and (max-width: 400px) {

  } */