.pricing-container {
  padding: 60px;
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  color: white;
  background-color: #040B1C;
}

.pricing-title {
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 2px solid #ddd;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .pricing-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .pricing-container {
    padding: 40px 20px;
  }
  .pricing-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefit-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}